<template>
  <v-menu
    ref="open"
    :close-on-content-click="false"
    v-model="open"
    :nudge-right="40"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        autocomplete="off"
        v-model="dateFormatted"
        @click:clear="clear_date"
        :label="label"
        placeholder="jour/mois/année"
        :dense="dense"
        append-icon="mdi-calendar"
        :rules="local_rules"
        :readonly="!edit"
        :clearable="clear"
        :disabled="disabled"
        :outlined="outlined"
        @click:append="on.click"
        @blur="date = parseDate(dateFormatted)"
        @focus="$event.target.select()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="fr-FR"
      @input="open = false"
      :min="date_min ? new Date(date_min).toISOString().substr(0, 10) : null"
      :max="date_max ? new Date(date_max).toISOString().substr(0, 10) : null"
      v-if="edit"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  name: "datepicker",
  props: {
    label: String,
    value: String,
    date_min: String,
    date_max: String,
    rules: Array,
    edit: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data: () => ({
    date: null,
    dateFormatted: null,
    open: false,
    local_rules: [],
    clear: true,
  }),
  created() {
    this.date = this.value;
    this.clear = this.clearable && this.edit;
    if (this.rules) this.local_rules = this.rules;
    this.local_rules.push(
      (v) => !v || this.format_date(v) || !this.edit || "Date Incorrecte"
    );
    this.local_rules.push(
      (v) => !v || this.compare_date(v) || !this.edit || "Date Incorrecte"
    );
  },
  methods: {
    compare_date(v) {
      let ok = true;
      var dateToCompare = moment(v, "DD/MM/YYYY");
      var today = moment(this.$store.state.today);
      if (this.date_max) {
        today = moment(this.date_max);
        ok = dateToCompare.startOf("day").isSameOrBefore(today.startOf("day"));
      }
      if (this.date_min && ok) {
        today = moment(this.date_min);
        ok = dateToCompare.startOf("day").isSameOrAfter(today.startOf("day"));
      }

      return ok;
    },
    format_date(value) {
      if (value) {
        return moment(value, "DD/MM/YYYY", true).isValid();
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    update() {
      //this.$value = this.dateFormatted;
      if (this.dateFormatted)
        if (this.dateFormatted.length == 10)
          this.date = this.parseDate(this.dateFormatted);
      this.$emit("change", this.date);
    },
    clear_date() {
      //this.$value = this.dateFormatted;

      this.dateFormatted = null;
      this.date = null;
      this.$emit("change", this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    dateFormatted() {
      this.update();
    },
  },
};
</script>
